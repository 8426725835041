<template>
    <div>
        <div class="data">
            <div class="title">
                全部问答列表
            </div>
            <div class="search">
                <search @condition="condition" :fields="searchFields">

                </search>
            </div>
            <el-table
                stripe
                border
                :data="page.data"
                style="width: 100%">
                <el-table-column
                    label="标题"
                >
                    <template slot-scope="scope">
                        <i class="el-icon-home"></i>
                        <el-link @click="jump(scope.$index,scope.row)" type="primary">{{ scope.row.title }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建人"
                    width="180">
                    <template slot-scope="scope">
                        {{ scope.row.username && scope.row.username.nickname }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="访问量"
                    width="150"
                    >
                    <template slot-scope="scope">
                        访问:{{scope.row.view||0}},回复:{{scope.row.reply||0}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核情况"
                    width="100"
                    >
                    <template slot-scope="scope">
                        {{reStoreKeyValue('review_status',scope.row.review_status)}}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="180">
                    <template slot-scope="scope">
                        <format-time :time="scope.row.create_time" format="Y-m-d H:i:s"></format-time>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="90">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="removeAnswer(scope.$index,scope.row)" icon="el-icon-delete"
                                   type="danger"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                background
                @current-change="pageChange"
                :current-page.sync="search.pageNumber"
                layout="total,prev, pager, next"
                :total="page.total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import F from '@/fields/fields';
import FormatTime from "@/components/common/format-time.vue";
import Search from "@/components/common/search.vue";
export default {
    components: {
        FormatTime,Search,
    },
    computed: {
        showFields: function () {
            return F.get('answer', 'verify').fields;
        },
        searchFields:function (){
            return F.get('answer', 'search').fields;
        },
    },
    data() {
        return {
            baseUrl: '/bbs/answer/manage',
            page: {
                data: [],
                total: 1,
            },
            search: {
                search:{},
                pageNumber: 1,
                type: '',
            }
        }
    },
    mounted() {
        // this.getData();
    },
    watch: {},
    methods: {
        condition(search){
            this.search.pageNumber=1;
            this.search.search=search;
            this.getData();
        },
        pageChange: function () {
            this.getData();
        },
        reStoreKeyValue:function (key,value){
              return this.$tools.reStoreKeyValue(key,value);
        },
        getData: function () {
            // this.search.pageNumber = this.page.currentPage;
            this.$bbs.get(this.baseUrl, {
                params: this.search,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.data = data.data.data;
                // this.page.currentPage = data.data.pageNumber;
            });
        },
        removeAnswer(index, data) {
            let title = data.title;
            if (title > 10) {
                title = title.substr(0, 10) + '...';
            }
            this.$confirm('你真的要删除:' + title + '?', '操作确认').then(() => {
                this.$bbs.delete(this.baseUrl, {data: {answer_id:data.answer_id}, need: {error: true}}).then(() => {
                    this.page.data.splice(index, 1);
                    this.page.total--;
                    this.$message.success('删除成功!');
                });
            });
        },
        jump(index, data) {
            let a = document.createElement('a');
            a.target = '_blank';
            if (data.review_status!=200){
                a.href = '/wenda/detail/' + data.answer_id + '.html?verify=1&type=answer&reply_id=' + (data.reply_id || '') + '&comment_id=' + (data.comment_id || '');
            }else{
                a.href = '/wenda/detail/' + data.answer_id + '.html';
            }
            a.click();

        }
    }
}

</script>

<style lang="less" scoped>
.data {
    margin-top: 1.5rem;

    .title {
        display: flex;
        background-color: #fff;
        font-weight: 600;
        padding: 15px 10px;
        margin-bottom: 10px;
    }
    .search{
        background-color: #fff;
        margin-bottom: 10px;
        padding: 15px 0;
    }
    .jump-link {
        display: flex;
        border-bottom: 1px solid #ccc;
        padding: 5px 0;

        .index {
            text-align: center;
            width: 60px;
            flex-shrink: 0;
        }
    }
}

.page {
    padding: 10px 0;
    background-color: #fff;
    margin-top: 10px;
}
</style>
